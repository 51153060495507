<template>
  <div class="home">
    <Row>
      <Form
        style="display: flex; justify-content: end"
        ref="formInline"
        :model="fromData"
        inline
        :label-width="10"
        label-position="right"
      >
        <FormItem>
          <DatePicker
            size="large"
            @on-change="fromData.startTime = $event"
            :value="fromData.startTime"
            format="yyyy-MM-dd"
            type="date"
            placeholder="开始时间"
            style="width: 180px"
          >
          </DatePicker>
        </FormItem>
        <FormItem>
          <DatePicker
            size="large"
            @on-change="onChangeEndTime"
            :value="fromData.endTime"
            format="yyyy-MM-dd"
            type="date"
            placeholder="结束时间"
            style="width: 180px"
          >
          </DatePicker>
        </FormItem>
        <FormItem>
          <Select
            size="large"
            v-model="fromData.orderType"
            placeholder="展示方式"
            style="width: 140px"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in DisplayTypeDataList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
        </FormItem>
        <!-- <FormItem>
          <Select
            v-model="fromData.projectId"
            placeholder="所属项目"
            filterable
            clearable
            style="width:140px"
          >
            <Option
              :value="item.v_project_id"
              v-for="(item, index) in elevatorFormItemProjectSelectList"
              :key="index"
            >
              {{ item.v_project_name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem>
          <Select
            v-model="fromData.villageId"
            placeholder="所属小区"
            clearable
            style="width:140px"
          >
            <Option
              v-for="(item, index) in villageFormItemSelectList"
              :key="index"
              :value="item.v_village_id"
            >
              {{ item.v_village_name }}</Option
            >
          </Select>
        </FormItem> -->
        <Button
          size="large"
          type="primary"
          shape="circle"
          icon="ios-search"
          @click="searchList()"
          >搜索
        </Button>
      </Form>
    </Row>
    <div>
      <div
        class="echart-box"
        id="E_zkcl"
        :style="`width:100%;height:600px;zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`"
      ></div>
    </div>
  </div>
</template>
<script>
import { getFaultDataStatisticsGroupByElevator } from '@/api/ststistics/index.js'
import {
  reqElevatorProjectSelectList,
  searchVillageSelectList,
} from '@/api/home/index.js'

export default {
  data() {
    return {
      model1: '',
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        legend: {
          data: [
            '全部',
            '故障',
            '困人',
            '全部(比率)',
            '故障(比率)',
            '困人(比率)',
          ],
          textStyle: {
            color: '#666',
            fontSize: 16,
            fontWeight: 'normal',
          },
          selected: {
            全部: true,
            故障: false,
            困人: false,
            '全部(比率)': true,
            '故障(比率)': false,
            '困人(比率)': false,
          },
          color: '#fff',
        },
        xAxis: [
          {
            type: 'category',
            data: ['外围分布', '瑞金总院'],
            axisLabel: {
              interval: 0,
              rotate: 0,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            min: 0,
            // max: 250,
            interval: 50,
            axisLabel: {
              textStyle: {
                fontSize: 16,
                color: '#fff',
              },
            },
          },
          {
            type: 'value',
            name: '',
            min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: '{value} %',
              textStyle: {
                fontSize: 16,
                color: '#fff',
              },
            },
          },
        ],
        series: [
          {
            name: '全部',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' ml'
              },
            },
            data: [6, 124],
          },
          {
            name: '故障',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' ml'
              },
            },
            data: [1, 13],
          },
          {
            name: '困人',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' ml'
              },
            },
            data: [0, 13],
          },
          {
            name: '全部(比率)',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' °C'
              },
            },
            data: [0.00203, 0.0101],
          },
          {
            name: '故障(比率)',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' °C'
              },
            },
            data: [0.00017, 0.01772],
          },
          {
            name: '困人(比率)',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' °C'
              },
            },
            data: [0, 0.01773],
          },
        ],
      },
      // 展示方式
      DisplayTypeDataList: [
        {
          value: 'asc',
          label: '升序',
        },
        {
          value: 'desc',
          label: '降序',
        },
      ],
      elevatorFormItemProjectSelectList: [],
      villageFormItemSelectList: [],

      fromData: {
        startTime: this.formatDate(-30) + '',
        endTime: this.formatDate(0) + ' 23:59:59',
        orderType: 'asc', //desc降序 asc升
        orderBy: 'total',
      },
      zoom: 1,
    }
  },
  computed: {
    theme() {
      return this.$store.getters.theme
    },
  },
  mounted() {
    
     let data = document.body.style.zoom.split('%')[0]-0
    this.zoom = 100 /data
    window.addEventListener('resize', () => {
      this.zoom = 100 / data
    })

    this.searchList()

    reqElevatorProjectSelectList().then((res) => {
      this.elevatorFormItemProjectSelectList = res.info.sort((a, b) =>
        a.v_project_name.localeCompare(b.v_project_name, 'zh-Hans-CN')
      )
    })
  },

  watch: {
    'formItem.vProjectId': function (newObj, OldObj) {
      if (newObj) {
        searchVillageSelectList({ projectId: newObj }).then((res) => {
          this.villageFormItemSelectList = res.info
        })
      } else {
        this.villageFormItemSelectList = []
      }
    },
    'fromData.orderBy'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchList()
      }
    },
    theme(newVal, oldVal) {
      if (newVal != oldVal) {
        this.searchList()
      }
    },

    
  },

  methods: {
    searchList() {
      getFaultDataStatisticsGroupByElevator(this.fromData).then((res) => {
        let myChart = this.$echarts.init(document.getElementById('E_zkcl'))
        if (res.info) {
          this.option.xAxis[0].data = res.info.categories.map((item) => {
            return {
              value: item,
              textStyle: {
                color: this.theme == 'dark' ? '#fff' : '',
                fontSize: 16,
              },
            }
          })

          let arr = []
          let arr2 = []

          res.info.series.forEach((item) => {
            if (item.name == '急修') {
              item.name = '故障'
            }
            if (item.name == '急修(比率)') {
              item.name = '故障(比率)'
            }
            arr.push(item.name)
            if (item.type == 'column') {
              arr2.push({
                name: item.name,
                type: 'bar',
                tooltip: {
                  valueFormatter: function (value) {
                    return value
                  },
                },
                data: item.data,
              })
            } else {
              arr2.push({
                name: item.name,
                type: 'line',
                tooltip: {
                  valueFormatter: function (value) {
                    return value
                  },
                },
                yAxisIndex: 1,
                data: item.data,
              })
            }
          })
          // this.option.legend.data = arr
          this.option.series = arr2
          this.option.yAxis.forEach((item) => {
            item.axisLabel.color = this.theme == 'dark' ? '#fff' : ''
          })
        }
        myChart.setOption(this.option)
        let that = this
        myChart.on('legendselectchanged', function (params) {
          if (params.name == '全部' || params.name == '全部(比率)') {
            that.option.legend.selected['全部'] = true
            that.option.legend.selected['故障'] = false
            that.option.legend.selected['困人'] = false
            that.option.legend.selected['全部(比率)'] = true
            that.option.legend.selected['故障(比率)'] = false
            that.option.legend.selected['困人(比率)'] = false
            that.fromData.orderBy = 'total'
          }
          if (params.name == '故障' || params.name == '故障(比率)') {
            that.option.legend.selected['全部'] = false
            that.option.legend.selected['故障'] = true
            that.option.legend.selected['困人'] = false
            that.option.legend.selected['全部(比率)'] = false
            that.option.legend.selected['故障(比率)'] = true
            that.option.legend.selected['困人(比率)'] = false
            that.fromData.orderBy = 'repairs'
          }
          if (params.name == '困人' || params.name == '困人(比率)') {
            that.option.legend.selected['全部'] = false
            that.option.legend.selected['故障'] = false
            that.option.legend.selected['困人'] = true
            that.option.legend.selected['全部(比率)'] = false
            that.option.legend.selected['故障(比率)'] = false
            that.option.legend.selected['困人(比率)'] = true
            that.fromData.orderBy = 'personTrapped'
          }
          myChart.hideLoading()
          myChart.setOption(that.option)
        })
      })
    },
    onChangeEndTime(e) {
      this.fromData.endTime = e + ' 23:59:59'
    },
  },
}
</script>
<style scoped>
.home {
  position: relative;
  padding: 10px 30px 0 10px;
}
::v-deep .ivu-select-selected-value {
  /* color: #fff!important; */
}
</style>